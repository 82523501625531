<template>
  <div>
    <div class="flex">
      <LeftNavIcons v-if="mobileView" :routesList="routes" />
      <div :class=" mobileView ? `main_container smv_main_container` : ``" class="bg-screen_bg"  @click="handleDashboadMenu(false)">
        <div id="overlay"></div>
        <div v-if="!mobileView">
          <LeftNavIcons :routesList="routes" />
        </div>
        <TopNavbar />
      </div>
    </div>
    <!-- <div id="layoutContent" class="min-h-screen main_content_box bg-white">
      <div class="cust_card mt-2 -ml-1">
        <router-view />
      </div>
    </div> -->
    <div id="layoutContent" class="min-h-screen main_content_box bg-white">
      <router-view />
    </div>
    <loader
      v-if="$store.getters.showLoader"
      v-bind:data="$store.getters.loaderMessage"
    ></loader>
    <snakBar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snakBar>
      <DownloadLoader/>
  </div>
</template>
<script>
import { getCookies } from "@/utils/cookies";
import {HRM} from '../router/index.js'
import LeftNavIcons from "@/View/Dashboard/components/leftNavbar.vue";
import TopNavbar from "@/View/Dashboard//components/topNavbar.vue";
// import {FilterPermissions} from '@/utils/Permissions.js'
import Loader from '@/View/components/Loader.vue'
import snakBar from '@/View/components/SnakBar.vue'
import DownloadLoader from '@/View/components/downloadLoader'
export default {
  name: "landing-page",
  components: {
    TopNavbar,
    DownloadLoader,
    LeftNavIcons,
    Loader,
    snakBar,
  },
  data() {
    return {
      userDetail: {},
      mobileView: false,
      extraSmall: false,
      routes: [],
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.resizeWindowHandler();
    this.userDetail = JSON.parse(localStorage.getItem('employeeData1'))
    this.getNavigationIconFromRouterWithPermissions()
    if (this.$route.path === '/dashboard') {
      console.log('userDetail', this.$route.path)
      this.$router.push({name: 'Projects'})
    }
    this.$root.$on("drawercheckLOcked", (data) => {
      console.log("check emit call", data);
      if (data) {
        document.getElementById("mySidenav").style.minWidth = "260px";
        document.getElementById("menu_items_box").style.left = "275px";
        document.getElementById("history_list_main_box").style.left = "275px";
        document.getElementById("myTopnav").style.left = "260px";
        document.getElementById('layoutContent').style.paddingLeft = '275px'
      } else {
        document.getElementById("overlay").style.display = "none";
        document.getElementById("mySidenav").style.width = "92px";
        document.getElementById("menu_items_box").style.left = "92px";
        document.getElementById("history_list_main_box").style.left = "92px";
        document.getElementById("myTopnav").style.left = "80px";
        document.getElementById('layoutContent').style.paddingLeft = '95px'
      }
    });
    this.$root.$on("manageDashboard", (data) => {
      if (data) {
        this.handleDashboadMenu(data);
      }
    });
    if (getCookies("lockDrawer") === "draweLocked" && !this.mobileView) {
      document.getElementById('layoutContent').style.paddingLeft = '275px'
      document.getElementById("mySidenav").style.minWidth = "260px";
      document.getElementById("menu_items_box").style.left = "275px";
      document.getElementById("history_list_main_box").style.left = "275px";
      document.getElementById("myTopnav").style.left = "260px";
    }
    window.scrollTo(0, 0);
    this.$store.dispatch("GetDevicePersistentRequest");
    let body = document.querySelector(".has-navbar-fixed-top");
    if (body !== null) {
      body.classList.remove("has-navbar-fixed-top");
    }
  },
  beforeDestroy() {
    this.$root.$off("drawercheckLOcked");
    this.$root.$off("favoriteTrue");
  },
  methods: {
    getNavigationIconFromRouterWithPermissions () {
      console.log('HRM.children', HRM.children)
      HRM.children.forEach(b => {
        // let permission = false
        if (!b.hidden && b.meta !== undefined) {
          console.log('this.userDetail.isSuperAdmin', this.userDetail)
          if (this.userDetail.isSuperAdmin || b.name === 'Projects') {
            this.routes.push(b)
          } else {
            if (b.name === 'Projects') {
              this.routes.push(b)
            }
            // if (b.meta !== undefined) {
            //   for (let a = 0; a < b.meta.permissionList.length; a++) {
            //     if (FilterPermissions(b.meta.permissionList[a])) {
            //       this.routes.push(b)
            //       break
            //     }
            //   }
            // }
          }
        }
      })
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 600) {
        this.extraSmall = true;
      } else {
        this.extraSmall = false;
      }
    },
    handleDashboadMenu(val) {
      console.log('--------------------val--------------------', val)
      if (this.mobileView) {
        document.getElementById("overlay").style.display = "none";
        document.getElementById("sv_smySidenav").style.minWidth = "0px";
      } else if (getCookies("lockDrawer") === "draweUnLocked") {
        if (val === false) {
          document.getElementById("overlay").style.display = "none";
          document.getElementById("mySidenav").style.width = "80px";
            "95px";
          document.getElementById("myTopnav").style.left = "80px";
        }
      }
      // if (this.extraSmall) {
      //   document.getElementById("searchInputBox").style.display = "none";
      //   if (!val) {
      //     document.getElementById("sv_smySidenav").style.minWidth = "0px";
      //     document.getElementById("overlay").style.display = "none";
      //   }
      // }
    }
  }
};
</script>
<style scoped>

</style>