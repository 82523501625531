<template>
  <svg :width="width" :height="height" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--noto" preserveAspectRatio="xMidYMid meet">

<path fill="#ffffff" d="M87.85 6.19H16.8v115.45h94.62V28.8z">

</path>

<g fill="none" stroke="black" stroke-width="3.865" stroke-linecap="round" stroke-miterlimit="10">

<path d="M33.34 41.05H94.5">

</path>

<path d="M33.34 55.68H94.5">

</path>

<path d="M33.34 70.3H94.5">

</path>

<path d="M33.34 84.93H94.5">

</path>

<path d="M33.34 99.56h26.15">

</path>

</g>

<path d="M109.45 23.59L92.79 6.88A10.555 10.555 0 0 0 85.54 4h-68.5c-1.55 0-2.81 1.26-2.81 2.81v114.38c0 1.55 1.26 2.81 2.81 2.81h93.93c1.55 0 2.81-1.26 2.81-2.81V31.28c-.01-2.91-2.21-5.69-4.33-7.69zm.32 96.41H18.23V8h64.66c2.12 0 3.85 1.72 3.85 3.85v17.88h17.34c3.14 0 5.69 1.73 5.69 5.69V120z" fill="black">

</path>

</svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'black'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
