<template>
  <!-- <svg :width="width" :height="height" fill="black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="folder-edit" class="icon glyph"><path d="M21.41,16.05l-5.65,5.66a1,1,0,0,1-.71.29H12.22a1,1,0,0,1-1-1V18.17a1.05,1.05,0,0,1,.29-.71l5.66-5.65a2,2,0,0,1,2.83,0l1.41,1.41A2,2,0,0,1,21.41,16.05ZM20,5H12.83L10.41,2.59A2,2,0,0,0,9,2H4A2,2,0,0,0,2,4V17a2,2,0,0,0,2,2H9.22v-.83a3,3,0,0,1,.88-2.12l5.66-5.66a4,4,0,0,1,5.66,0L22,11V7A2,2,0,0,0,20,5Z"></path></svg> -->
  <svg :width="width" :height="height" fill="black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="folder-alt" class="icon glyph"><path d="M19,8H7A3,3,0,0,0,4.26,9.78L2,14.87V5A2,2,0,0,1,4,3H8a2.05,2.05,0,0,1,1.4.56L11.83,6H17A2,2,0,0,1,19,8Zm2.81,2.42A1,1,0,0,0,21,10H7a1,1,0,0,0-.91.59l-4,9A1,1,0,0,0,3,21H18a1,1,0,0,0,.95-.68l3-9A1,1,0,0,0,21.81,10.42Z"></path></svg>
  <!-- <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" :width="width" :height="height" fill="black"><path d="M 15 3 L 15 19.9375 L 19 17.90625 L 23 20 L 23 3 Z M 11 6 C 7.636719 6 5 8.636719 5 12 L 5 33 C 5 36.363281 7.636719 39 11 39 L 12 39 L 12 47.21875 L 21.375 39 L 40 39 C 43.363281 39 46 36.363281 46 33 L 46 12 C 46 8.636719 43.363281 6 40 6 L 25 6 L 25 22.375 L 19 30 L 13 22.375 L 13 6 Z M 19 20.09375 L 15.28125 21.96875 L 17.65625 25 L 20.3125 25 L 22.65625 22 Z M 19.125 30 L 44 30 L 44 33 C 44 35.242188 42.242188 37 40 37 L 23.53125 37 Z"/></svg> -->
  </template>
  <script>
  export default {
    name: 'sheduleSvg', // vue component name
    props: {
      color: {
        type: String,
        default: 'black'
      },
      width: {
        type: String,
        default: '40px'
      },
      height: {
        type: String,
        default: '40px'
      }
    },
    data () {
      return {
      }
    }
  }
  </script>
  