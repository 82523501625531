import Layout from '@/View/CMS/Layout/layout.vue'
import colors from '@/assets/colors.json'
export const HRM = { // dashboard object start
    path: '/',
    component: Layout,
    hidden: false,
    redirect: '/Projects',
    name: 'HRM',
    meta: {
        breadCrumb: 'HRM',
        title: 'dashboards',
        icon: 'fas fa-border-all',
        loadPage: 'hrmDashboard',
        selected: false,
        permission: ''
    },
    children: [
        // {
        //     path: '/dashboard',
        //     component: () =>
        //         import ('@/View/CMS/dashboard/dashboard.vue'),
        //     name: 'HRMDashboard',
        //     meta: {
        //         breadCrumb: 'Dashboard',
        //         icon: 'fas fa-border-all',
        //         title: 'Dashboard',
        //         loadPage: 'hrmDashboard',
        //         permissionList: ['abc', 'def'],
        //         routeIcon: 'dashboardSvg',
        //         routIconSize: { height: '40px', width: '40px', color: colors.gray2 }
        //     }
        // },
        // {
        //   path: '/user',
        //   component: () => import('@/View/CMS/user/userlist.vue'),
        //   name: 'Users',
        //   meta: {
        //       breadCrumb: 'Dashboard',
        //       icon: 'fas fa-border-all',
        //       title: 'Dashboard',
        //       loadPage: 'Users',
        //       permissionList: ['abc', 'def'],
        //       routeIcon: 'listingSvg',
        //       routIconSize: {height: '40px', width: '40px', color: colors.gray2}
        //   }
        // },
        {
            path: '/projects',
            component: () =>
                import ('@/View/CMS/project/projectList.vue'),
            name: 'Projects',
            meta: {
                breadCrumb: 'Projects',
                icon: 'fas fa-border-all',
                title: 'Projects',
                loadPage: 'Projects',
                permissionList: ['abc', 'def'],
                routeIcon: 'leftArrowSvg',
                routIconSize: { height: '40px', width: '40px', color: colors.gray2 }
            }
        },
        { // Setting object start
            path: '/setting',
            component: () =>
                import ('@/View/CMS/Main/index.vue'),
            hidden: false,
            redirect: '/settings',
            name: 'Setting',
            meta: {
                breadCrumb: 'Setting',
                title: 'Settings',
                icon: 'fa fa-cog',
                routeIcon: 'SettingsSvg',
                loadPage: 'Setting',
                routIconSize: { height: '40px', width: '40px' },
                selected: false,
                permissionList: ['IsAdmin']
            },
            children: [{
                    path: '/settings',
                    component: () =>
                        import ('@/View/CMS/Setting/setting.vue'),
                    name: 'hrm',
                    meta: {
                        breadCrumb: 'Dashboard',
                        loadPage: 'Setting',
                        title: 'Dashboard'
                    }
                },
                {
                    path: '/user',
                    component: () =>
                        import ('@/View/CMS/user/userlist.vue'),
                    name: 'Users',
                    meta: {
                        breadCrumb: 'users',
                        title: 'userList',
                        loadPage: 'hrmDashboard',
                        permissionList: ['abc', 'def'],
                    }
                },
                {
                    path: '/fieldList',
                    component: () =>
                        import ('@/View/CMS/field/fieldList.vue'),
                    name: 'fields',
                    meta: {
                        breadCrumb: 'fields',
                        title: 'FieldList',
                        loadPage: 'hrmDashboard',
                        permissionList: ['abc', 'def'],
                    }
                }
                // {
                //     path: '/project/:projectId',
                //     component: () =>
                //         import ('@/View/CMS/project/specificProject.vue'),
                //     name: 'specificProject',
                //     meta: {
                //         breadCrumb: 'specificProject',
                //         title: 'specificProject',
                //         loadPage: 'hrmDashboard',
                //         permissionList: ['abc', 'def'],
                //     }
                // }
            ]
        }
    ]
}