<template>
  <div v-if="mobileView">
    <div id="myTopnav" class="topNav bg-screen_bg">
      <div class="menu_iconss bg-white justify-between">
        <div v-if="mobileView" class="dashboard_menus d_menu_icon">
          <!-- <i  @click="smallViewOpenDrawer()" class="mdi mdi-menu sv_menu_icon"></i> -->
          <img
            @click="smallViewOpenDrawer()"
            height="40px"
            width="40px"
            src="@/assets/images/svg/menu-icon.svg"
            alt=""
          />
        </div>
        <div v-if="mobileView" class="dashboard_menus d_menu_icon">
          <img
            @click="LogOut()"
            height="50px"
            width="50px"
            src="@/assets/images/logout.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- <div v-if="!mobileView" id="myTopnav" class="topNav bg-white" style="justify-content: flex-end;">
      <div v-if="$route.meta.loadPage === 'hide-top-Menu'" class="go-back-barr bg-white flex items-center">
        <span class="text-primary heading-1 mt-1 cursor-pointer" @click="$router.push({name: 'dashboard'})">
          <i class="fa-solid fa-arrow-left"></i>
        </span>
        <span class="text-text1 heading-3 ml-2">{{$route.meta.title}}</span>
      </div>
      <div class="user_info_box bg-white">
        <div
          @click="handleDashboadMenu('showAvtarMenu')"
          class="dashboard_menus"
        >
          <LoginUserProfile />
        </div>
      </div>
    </div> -->
    <!-- <div
      :style="showDashboadMenus ? `display: block` : `display:none`"
      @click="handleDashboadMenu(true)"
      id="menu_items_box"
    >
      <ModuleList />
    </div>
    <div
      :style="showAddNewWorkMenus ? `display: block` : `display:none`"
      @click="handleDashboadMenu('addNewWork')"
      id="menu_items_box"
    >
      <AddNewWork />
    </div>
    <div
      id="history_list_main_box"
      :style="showHistory ? `display: block` : `display:none`"
      @click="handleDashboadMenu('history')"
    >
      <HistoryList :showHistory="showHistory" />
    </div>
    <div
      id="avatar_menu_box"
      v-if="showAvtarMenu"
      :style="showAvtarMenu ? `display: block` : `display:none`"
      @click="handleDashboadMenu('showAvtarMenu')"
    >
      <AvatarMenu :showAvtMenu="showAvtarMenu" />
    </div>
    <div
      v-if="extraSmall"
      @click="showSearch()"
      class="smv_search"
      id="searchInputBox"
    >
      <div class="cust_card smv_search_box">
        <input
          autofocus
          type="text"
          name=""
          placeholder="Search Customer.."
          id="searchInput"
        />
      </div>
    </div> -->
  </div>
  <div v-else>
    <div id="myTopnav" class="topNav cust_card m-2 mt-0 bg-white hover:shadow-md rounded-md">
      <div class="menu_iconss bg-white justify-between">
        <div  class="dashboard_menus d_menu_icon">
          <!-- <i  @click="smallViewOpenDrawer()" class="mdi mdi-menu sv_menu_icon"></i> -->
          <!-- <p class="heading-1 text-text1 font-bold">CMS</p> -->
          <div class="p-0 flex justify-center">
            <div class="font-semibold text-text1" style="font-size:25px;letter-spacing: 0.5px;">CMS{{projectName !== '' ? ": " : ''}}   {{projectName}}</div>
          </div>
        </div>
        <div class="flex items-center">
          <div class="pr-6">
            <div class="items-center grid text-center">
              <span class="heading-4 text-black cursor-pointer">{{EmpObj.firstName}} {{EmpObj.lastName}}</span>
              <span class="heading-5 text-black cursor-pointer">{{EmpObj.emailAddress}}</span>
            </div>
          </div>
          <div class="dashboard_menus d_menu_icon cursor-pointer">
            <img
              @click="LogOut()"
              height="35px"
              width="35px"
              src="@/assets/images/logout.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="!mobileView" id="myTopnav" class="topNav bg-white" style="justify-content: flex-end;">
      <div v-if="$route.meta.loadPage === 'hide-top-Menu'" class="go-back-barr bg-white flex items-center">
        <span class="text-primary heading-1 mt-1 cursor-pointer" @click="$router.push({name: 'dashboard'})">
          <i class="fa-solid fa-arrow-left"></i>
        </span>
        <span class="text-text1 heading-3 ml-2">{{$route.meta.title}}</span>
      </div>
      <div class="user_info_box bg-white">
        <div
          @click="handleDashboadMenu('showAvtarMenu')"
          class="dashboard_menus"
        >
          <LoginUserProfile />
        </div>
      </div>
    </div> -->
    <!-- <div
      :style="showDashboadMenus ? `display: block` : `display:none`"
      @click="handleDashboadMenu(true)"
      id="menu_items_box"
    >
      <ModuleList />
    </div>
    <div
      :style="showAddNewWorkMenus ? `display: block` : `display:none`"
      @click="handleDashboadMenu('addNewWork')"
      id="menu_items_box"
    >
      <AddNewWork />
    </div>
    <div
      id="history_list_main_box"
      :style="showHistory ? `display: block` : `display:none`"
      @click="handleDashboadMenu('history')"
    >
      <HistoryList :showHistory="showHistory" />
    </div>
    <div
      id="avatar_menu_box"
      v-if="showAvtarMenu"
      :style="showAvtarMenu ? `display: block` : `display:none`"
      @click="handleDashboadMenu('showAvtarMenu')"
    >
      <AvatarMenu :showAvtMenu="showAvtarMenu" />
    </div>
    <div
      v-if="extraSmall"
      @click="showSearch()"
      class="smv_search"
      id="searchInputBox"
    >
      <div class="cust_card smv_search_box">
        <input
          autofocus
          type="text"
          name=""
          placeholder="Search Customer.."
          id="searchInput"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import {getCookies} from '@/utils/cookies'
import {removeCookies} from '@/utils/cookies'
// import AvatarMenu from './avatarMenu.vue'
// import ModuleList from './moduleList.vue'
// import AddNewWork from './addNewWork.vue'
// import HistoryList from './historyList.vue'
// import LoginUserProfile from './loginuserProfile.vue'
import router from '@/router/index.js'
export default {
  components: {
    // AvatarMenu,
    // ModuleList,
    // AddNewWork,
    // HistoryList,
    // LoginUserProfile
  },
  name: "leftNavbar",
  props: ["routesList"],
  data() {
    return {
      projectName: "",
      EmpObj: [],
      isfavorite: false,
      setFavIcon: false,
      menu: false,
      drawerIsOpen: false,
      showGlobalSearch: false,
      showDashboadMenus: false,
      showAddNewWorkMenus: false,
      showHistory: false,
      showAddFavorite: false,
      showAvtarMenu: false,
      mobileView: false,
      extraSmall: false,
      favoriteList: []
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    let Isadmin =  JSON.parse(localStorage.getItem('employeeData1'))
    this.EmpObj = Isadmin
    if (this.$route.meta.module === "project") {
        this.showGlobalSearch = true
      }
    router.afterEach(() => {
      // console.log('layout_mount=======================', this.$route)
      if (this.$route.meta.module === "project") {
        this.showGlobalSearch = true
      }
      setTimeout(() => {
        // this.setNavigation(this.$route)
      }, 100);
    })
    this.$root.$on('projectName', (data) => {
        if (data) {
          this.projectName = data
        }
      })
    this.$root.$on("pageIsfavorite", (data) => {
      if (data === true) {
        this.isfavorite = true
        this.setFavIcon = true
      } else {
        // console.log('check ====1');
        this.isfavorite = false
      }
    });
    this.$root.$on("closeHistorySection", (data) => {
      setTimeout(() => {
        if (data === true) {
          // console.log('cehck 1');
          this.showHistory = false;
        }
      }, 100);
    });
    this.$root.$on("favoritDelete", (data) => {
      setTimeout(() => {
        if (data === true) {
          this.isfavorite = false
        this.setFavIcon = false
        this.showAddFavorite = false;
        }
      }, 100);
    });
    this.$root.$on("favoriteTrue", (data) => {
      setTimeout(() => {
        if (data === true) {
          this.showAddFavorite = false;
          if (!this.setFavIcon) {
            this.isfavorite = false
            //  console.log('check ====1');
          }
        }
      }, 100);
    });
    this.resizeWindowHandler();
    this.$root.$on("closeCardPopUp", () => {
      // console.log('cehck 2');
      this.showHistory = false;
      this.showAddFavorite = false;
      if (!this.setFavIcon) {
        this.isfavorite = false
        //  console.log('check ====1');
      }
      this.showDashboadMenus = false;
      this.showAddNewWorkMenus = false;
      this.showAvtarMenu = false;
    });
  },
  watch: {},
  methods: {
    LogOut () {
      removeCookies('authToken')
      localStorage.clear()
      this.$store.dispatch('GetEmployeePermission', true)
      this.$router.push({name: 'login'})
      location.reload()
    },
    showSearch() {
      setTimeout(() => {
        if (this.extraSmall) {
          document.getElementById("searchInputBox").style.display = "block";
        }
        document.getElementById("searchInput").focus();
      }, 100);
    },
    smallViewOpenDrawer() {
      if (this.mobileView) {
        setTimeout(() => {
          document.getElementById("sv_smySidenav").style.minWidth = "260px";
          document.getElementById("overlay").style.display = "block";
        }, 10);
      }
      document.getElementById("overlay").style.display = "block";
      document.getElementById("sv_smySidenav").style.minWidth = "260px";
      document.getElementById("menu_items_box").style.left = "275px";
      document.getElementById("history_list_main_box").style.left = "275px";
      this.showDashboadMenus = false;
      this.showAddNewWorkMenus = false;
      this.showHistory = false;
      this.showAddFavorite = false;
      if (!this.setFavIcon) {
        this.isfavorite = false
        //  console.log('check ====1');
      }
      this.showAvtarMenu = false;
      this.drawerIsOpen = true;
    },
    handleDashboadMenu(val) {
      // console.log('cehck 3');
      this.showDashboadMenus = false;
      this.showAddNewWorkMenus = false;
      this.showHistory = false;
      this.showAddFavorite = false;
      if (!this.setFavIcon) {
        this.isfavorite = false
        //  console.log('check ====1');
      }
      this.showAvtarMenu = false;
      if (this.mobileView && !this.extraSmall) {
        // console.log('overlay 1', document.getElementById('overlay'))
        // console.log('menu_items_box 1', document.getElementById('menu_items_box'))
        // console.log('history_list_main_box 1', document.getElementById('history_list_main_box'))
        // console.log('favorite_add_main_box 1', document.getElementById('favorite_add_main_box'))
        document.getElementById("overlay").style.display = "none";
        document.getElementById("sv_smySidenav").style.minWidth = "0px";
        document.getElementById("menu_items_box").style.left = "95px";
        document.getElementById("history_list_main_box").style.left = "95px";
        this.drawerIsOpen = false;
      } else if (getCookies("lockDrawer") === "draweUnLocked") {
        if (val === false && !this.extraSmall) {
          if (!this.extraSmall) {
            // console.log('overlay 1', document.getElementById('overlay'))
            // console.log('mySidenav 1', document.getElementById('mySidenav'))
            // console.log('menu_items_box 1', document.getElementById('menu_items_box'))
            // console.log('history_list_main_box 1', document.getElementById('history_list_main_box'))
            // console.log('favorite_add_main_box 1', document.getElementById('favorite_add_main_box'))
            // console.log('myTopnav 1', document.getElementById('myTopnav'))
            document.getElementById("overlay").style.display = "none";
            document.getElementById("mySidenav").style.width = "80px";
            document.getElementById("menu_items_box").style.left = "95px";
            document.getElementById("history_list_main_box").style.left =
              "95px";
            document.getElementById("myTopnav").style.left = "80px";
          }
          this.drawerIsOpen = false;
        }
      }
      // if (this.extraSmall) {
      //   // console.log('sv_smySidenav 1', document.getElementById('sv_smySidenav'))
      //   // console.log('overlay 2', document.getElementById('overlay'))
      //   // console.log('menu_items_box 3', document.getElementById('menu_items_box'))
      //   // console.log('history_list_main_box 4', document.getElementById('history_list_main_box'))
      //   // console.log('favorite_add_main_box 5', document.getElementById('favorite_add_main_box'))
      //   document.getElementById("searchInputBox").style.display = "none";
      //   if (!val) {
      //     document.getElementById("sv_smySidenav").style.minWidth = "0px";
      //     document.getElementById("overlay").style.display = "none";
      //     document.getElementById("menu_items_box").style.left = "60px";
      //     document.getElementById("history_list_main_box").style.left = "60px";
      //   }
      // }
      setTimeout(() => {
        if (val === true) {
          this.showDashboadMenus = true;
        }
        if (val === "addNewWork") {
          this.showAddNewWorkMenus = true;
        }
        if (val === "history") {
          // console.log('cehck 4');
          this.showHistory = true;
        }
        if (val === "favoritAdd") {
          this.showAddFavorite = true;
          this.isfavorite = true
          //  console.log('check ====1');
        }
        if (val === "showAvtarMenu") {
          this.showAvtarMenu = true;
        }
      }, 0.01);
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 600) {
        this.extraSmall = false;
      } else {
        this.extraSmall = false;
      }
    },
    beforeDestroy() {
    this.$root.$off("projectName");
    this.$root.$off("drawercheckLOcked");
    this.$root.$off("favoriteTrue");
    this.$root.$off("pageIsfavorite");
    this.$root.$off("closeHistorySection");
    this.$root.$off("favoritDelete");
  },
  },
};
</script>
<style scoped>
.menu_iconss {
  width: inherit;
  padding: 0px 0px 0px 0px;
  display: inherit;
  align-items: center;
}
.row_user_svg {
  height: 25px;
  width: 25px;
}
</style>